<template>
  <div class="userFeedback">
    <van-form @submit="onSubmit" :scroll-to-error="true" :show-error="false" ref="form">
      <van-field
        readonly
        required
        clickable
        name="softWareTypeLabel"
        :value="softWareTypeLabel"
        label="软件选择"
        placeholder="请选择反馈软件"
        :rules="[{ required: true, message: '请选择反馈软件！',trigger:'onSubmit'}]"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          :default-index="columns.length - 1"
          value-key="label"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field name="feedBackType" required label="反馈类型" :rules="[{ required: true, message: '请选择反馈类型！' ,trigger:'onSubmit'}]">
        <template #input>
          <van-radio-group v-model="feedBackType" direction="horizontal" class="radioGroup">
            <van-radio name="1">功能问题</van-radio>
            <van-radio name="2">界面问题</van-radio>
            <van-radio name="3">体验问题</van-radio>
            <van-radio name="4">兼容性问题</van-radio>
            <van-radio name="5">性能问题</van-radio>
            <van-radio name="6">其他问题</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
        v-model="detailContent"
        name="detailContent"
        rows="2"
        autosize
        type="textarea"
        maxlength="200" 
        show-word-limit
        label="反馈内容"
        placeholder="请输入反馈内容"
      />

      <van-field
        name="audioFileUrl"
        label="语音反馈">
        <template #input>
          <img src="../../assets/img/userFeedback/microphone.png" alt="" @click="soundRecordingClick">
          <div class="prompt" v-if="audioFileUrl">
            <div class="small-triangle"></div>
              <div class="prompt-content" @click="handlePlay">
                <img :src="require(recordingstatus==='03'?'../../assets/img/userFeedback/soundRecording.gif':'../../assets/img/userFeedback/soundRecording.png')" style="width:15px;height:20px;margin-right:5px" alt="">
                <span>语音文件</span>
                <span>
                  {{recorder && recorder.duration>=0.5 ? Math.round(recorder.duration)+ 's':''}}
                </span>
             </div>
          </div>
          <van-icon v-if="audioFileUrl" style="margin-left:10px" size="20" name="close" @click="handleDestroy(false)" />
        </template>
      </van-field>


      <van-field name="fileUrl" label="上传图片">
        <template #input>
          <van-uploader 
          v-model="fileUrl" 
          :before-read="beforeRead" 
          :after-read="afterRead" 
          @delete="deleteImg"
          multiple 
          accept="image/*" 
          :max-count="5"
          />
        </template>
      </van-field>
      
      <van-field
        v-model="linkName"
        required
        name="linkName"
        label="联系人"
        placeholder="请输入联系人"
        :rules="[{ required: true, message: '请输入联系人！' }]"
      />
      <van-field
        v-model="telNumber"
        required
        ref="telNumberField"
        name="telNumber"
        label="手机号码"
        maxlength="11"
        type="tel"
        placeholder="请输入手机号码"
        :rules="telNumberRules"
      />
      <van-field
        v-model="mobileVerifyCode"
        required
        center
        clearable
        label="验证码"
        name="mobileVerifyCode"
        placeholder="请输入短信验证码"
        :rules="[{ required: true, message: '请输入短信验证码！' }]"
      >
        <template #button>
          <van-button size="small" :disabled="codeDisabled" plain type="info" native-type='button' @click.stop="smsClick">{{codeText}}</van-button>
        </template>
      </van-field>
      <div class="agree-checkbox">
        <van-checkbox :icon-size="14" v-model="agreementChecked"
          >我已阅读并同意</van-checkbox
        ><span class="xy" @click="agreementShow = true"
          >《用户隐私协议》</span
        >
      </div>
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit" :loading="submitLoading">提交</van-button>
      </div>
      <div style="margin: 16px;" v-if="within">
        <van-button round block type="default" native-type='button' @click.stop="GoBack">返回</van-button>
      </div>
    </van-form>
    <!-- 弹窗同意协议 -->
    <van-dialog
      v-model="agreeConfrim"
      title="用户隐私协议"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="agree-tip">
        为了更好的保障您的合法权益，请阅读并同意以下 协议<span
          class="xy"
          @click="agreementShow = true"
          >《用户隐私协议》</span
        >，同意后 验证码将发送到您的手机。
      </div>
      <div class="agree-btn">
        <van-button type="default" @click="agreeConfrim = false"
          >不同意</van-button
        >
        <van-button type="info" @click="consentAgreement">同意</van-button
        >
      </div>
    </van-dialog>
    <van-popup position="bottom" :style="{ height: '35%' }" v-model="soundRecordingModel" round :close-on-click-overlay="false" closeable close-icon="close" @click-close-icon="clickCloseIcon">
     <div class="soundRecording">
       <div class="container" :class="[isItAnimated?'container-play':'container-stop']" ref="containerRef">
        <span style="--d: 7"></span>
        <span style="--d: 6"></span>
        <span style="--d: 5"></span>
        <span style="--d: 4"></span>
        <span style="--d: 3"></span>
        <span style="--d: 2"></span>
        <span style="--d: 1"></span>
        <span style="--d: 0"></span>
        <span style="--d: 1"></span>
        <span style="--d: 2"></span>
        <span style="--d: 3"></span>
        <span style="--d: 4"></span>
        <span style="--d: 5"></span>
        <span style="--d: 6"></span>
        <span style="--d: 7"></span>
      </div>
      <div class="recordingDuration">
        {{recorder && recorder.duration.toFixed(2)>120.00?'120.00':recorder.duration.toFixed(2)}}
      </div>
      <div class="soundRecording-action">
        <div class="reRecord">
          <img @click="handleDestroy(false)" src="@/assets/img/userFeedback/withdraw.png" class="reRecordIcon" alt="">
          <!-- <van-icon @click="handleDestroy(false)" name="revoke" size="25" class="reRecordIcon"/> -->
          <span>重录</span>
        </div>
        <div class="reRecord">
          <img @click="handleStart" :src="require(`@/assets/img/userFeedback/${recordingstatus==='01'?'micIcon-red':'micIcon'}.png`)" alt="">
          <span>{{recordingstatus==='01'?'点击结束录音':'点击开始录音'}}</span>
        </div>
        <div class="reRecord">
          <van-loading v-if="uploadRecordLoading" />
          <img v-else @click="uploadRecord" src="@/assets/img/userFeedback/complete.png" class="reRecordIcon" alt="">
          <!-- <van-icon v-else @click="uploadRecord" name="passed" size="25" class="reRecordIcon"/> -->
          <span>完成</span>
        </div>
        <!-- <van-button icon="delete-o" type="info" plain round size="small" @click="handleDestroy(false)">删除</van-button>
        <van-icon :name="recordingstatus==='01'?'pause-circle-o':'stop-circle-o'" color="#1989fa" size="50" @click="handleStart" />
        <van-button icon="passed" type="info" plain round size="small" :loading="uploadRecordLoading" @click="uploadRecord">完成</van-button> -->
      </div>
     </div>
      
    </van-popup>
    <van-popup
        v-model="agreementShow"
        closeable
        position="bottom"
        :style="{ height: '100%' }"
      >
        <div class="agreement">
           <agreement-vue></agreement-vue>
        </div>
      </van-popup>
  </div>
</template>

<script>
import Recorder from 'js-audio-recorder'
import Vue from "vue";
import { RadioGroup, Radio,Icon } from 'vant';
import AliOss from '@/utils/alioss';
import { mobile_smsCode } from '@/api/invite';
import { feedback } from '@/api/userFeedback'
import agreementVue from './agreement.vue';

Vue.use(Radio);
Vue.use(RadioGroup)
Vue.use(Icon)
export default Vue.extend({
  components: {agreementVue},
  data() {
    return {
      within:false,
      telNumberRules:[
        {
          required: true,
          message: '请输入手机号码',
        },
        {
          pattern:/^[1][3,4,5,7,8,9][0-9]{9}$/,
          message: '请输入正确的手机号码',
        }
      ],
      agreeConfrim: false,
      agreementShow: false,
      agreementChecked:false,
      submitLoading:false,
      codeText:'获取验证码',
      smstimer:null, // 获取验证码倒计时
      codeDisabled: false, // 获取验证码按钮Disabled状态
      uploadRecordLoading:false,
      uploadfile: {
				type: '',
				name: ''
			}, // 上传单图的情况
			uploadfiles: [], // 上传多图的情况
      softWareTypeLabel:'计价软件',// 软件反馈选择
      softWareType:'1', // 软件反馈选择
      feedBackType:'', //反馈类型
      detailContent:'', // 详情
      linkName:'', // 联系人
      telNumber:'',// 手机号码
      mobileVerifyCode:'',// 手机验证码
      audioFileUrl:'', // 录音文件地址
      fileUrl:[], // 上传文件
      soundRecordingModel:false, // 录音弹框
      timer: null,  // 录音
      playTime: 0, // 录音时长
      recorder:null,
      recordingstatus:'00', // 00：未开始，01：正在录音，02:停止录音，03：播放录音
      columns: [
        {
          label: '材料信息',
          value: '6',
        },
        {
          label: '工程指标',
          value: '5',
        },
        {
          label: '标书检查',
          value: '4',
        },
        {
          label: '智能组价',
          value: '3',
        },
        {
          label: '算量软件',
          value: '2',
        },
        {
          label: '计价软件',
          value: '1',
        },
      ],
      showPicker: false,
      isItAnimated:false, // 是否播放动画
    };
  },
  watch:{
    playTime(newVal){
      if(Math.round(newVal)===Math.round(this.recorder.duration)){
        this.recordingstatus='02'
      }
    },
    'recorder.duration'(val){
      if(val.toFixed(2) > 120.00){
        this.handleStop() // 停止录音
        this.$toast.fail('录制时长已长达2分钟，已自动停止录制！')
      }

    }
  },
  created() {
    this.within=this.$route.query.within==='true'?true:false
    if(this.$store.getters.currentLoginMobile){
      this.telNumber=this.$store.getters.currentLoginMobile
    }
    this.recorder = new Recorder({
      sampleBits: 16, // 采样位数，支持 8 或 16，默认是 16
      sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，Chrome 是 48000
      numChannels: 1, // 声道数，支持 1 或 2， 默认是 1
    })
  },
  methods:{
    // 文件读取前触发
    beforeRead(e) {
      let filelist=[]
      if(!e.length){
        filelist.push(e)
      }else{
        filelist=e
      }
      if(filelist.length>5){
        this.$toast.fail('最多仅支持上传5张照片！')
        return
      }
      for (const item of filelist) {
        const ext = item.name.replace(/.*\./, '').toLowerCase();
        if (!['jpg', 'png', 'jpeg'].includes(ext)) {
          this.$toast.fail('所传素材格式不支持')
          return false;
        }
        if (e.size > 10 * 1024 * 1024) {
          this.$toast.fail('文件大小不能超过10M')
          return false
        }
      }
			return true
		},
    // 上传图片
   async afterRead(file){
      let url=""
      if (file.length > 0) {
        file.map(async (item, index) => {
					this.uploadfiles.push({
						name: item.file.name,
						type: item.file.type
					})
					this.fileUrl[index].status = 'uploading'
					this.fileUrl[index].message = '上传中...'
          url = await new AliOss().upload(file[index].file); // 调用oss上传
          if(url){
					  this.fileUrl[index].url = url
            this.fileUrl[index].status = 'done'
					  this.fileUrl[index].message = '上传成功'
          }

				})
      }else{
        this.uploadfile.name = file.file.name // 获取文件名
				this.uploadfile.type = file.file.type // 获取类型
				this.fileUrl[this.fileUrl.length - 1].status = 'uploading'
				this.fileUrl[this.fileUrl.length - 1].message = '上传中...'
        url = await new AliOss().upload(file.file); // 调用oss上传
        if(url){
				  this.fileUrl[this.fileUrl.length - 1].url = url
          this.fileUrl[this.fileUrl.length - 1].status = 'done'
				  this.fileUrl[this.fileUrl.length - 1].message = '上传成功'
        }
      }
    },
    // 删除图片
    deleteImg(file){
     (this.fileUrl || []).filter((v) => v.url !== file.url);
    },
    // 关闭录音弹框
    clickCloseIcon(){
      if (this.recorder || this.recorder.duration > 0) {
        this.handleDestroy(true)
      }
      this.soundRecordingModel=false
    },
    // 打开录音弹框
    soundRecordingClick(){
      if(this.audioFileUrl){
        return
      }
      this.soundRecordingModel=true
    },
    // 开始录音
    handleStart() {
      if(this.recordingstatus==='01'){
        this.handleStop()
      }else{
        this.recorder = new Recorder({
          sampleBits: 16, // 采样位数，支持 8 或 16，默认是 16
          sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，Chrome 是 48000
          numChannels: 1, // 声道数，支持 1 或 2， 默认是 1
        })
        Recorder.getPermission().then(() => {
          console.log('开始录音')
          this.recorder.start() // 开始录音
          this.recordingstatus='01'
          this.trendsStyle()
        }, (error) => {
          console.log(error,'error')
          if(error.message=='Permission denied'){
            this.$toast.fail('获取麦克风权限失败！')
          }else{
            this.$toast.fail('出错了！请联系管理员')
          }
        })
      }
    },
    trendsStyle(){
     this.isItAnimated = this.recordingstatus==='01'?true:false
    },
    // 停止录音
    handleStop() {
      console.log('停止录音')
      this.recorder.stop() // 停止录音
      this.recordingstatus='02'
      this.trendsStyle()
    },
    // 播放录音
    handlePlay(){
      this.recorder.play()
      this.recordingstatus='03'
      // 播放时长
      this.timer = setInterval(() => {
        try {
          this.playTime = this.recorder.getPlayTime()
        } catch (error) {
          this.timer = null
        }
      }, 100)
    },
    // 销毁录音
    handleDestroy(notVerifying) {
      if (!notVerifying && (this.recorder == null || this.recorder.duration === 0)) {
      this.$toast.fail('请先录音')
        return false
      }
      this.recorder.destroy() // 销毁实例
      this.timer = null
      this.recordingstatus='00'
      this.audioFileUrl=''
      this.trendsStyle()
    },
    // 上传录音
   async uploadRecord(){
      if (this.recorder == null || this.recorder.duration === 0) {
        this.$toast.fail('请先录音')
          return false
        }
      if(this.recordingstatus==='01'){
        this.$toast.fail('请先结束录音')
        return false
      }
      this.handleStop() // 停止录音
      this.timer = null
      const blob = this.recorder.getWAVBlob()
      const newbolb = new Blob([blob], { type: 'audio/wav' })
      const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
      this.uploadRecordLoading=true
      let url = await new AliOss().upload(fileOfBlob);
      if(url){
        this.audioFileUrl=url
        this.uploadRecordLoading=false
        this.soundRecordingModel=false
      }
    },
    // 重置表单
    resetForm(){
      this.softWareTypeLabel=''
      this.softWareType=''
      this.feedBackType=''
      this.detailContent=''
      this.audioFileUrl=''
      this.fileUrl=[]
      this.linkName=''
      this.telNumber=''
      this.mobileVerifyCode=''
    },
    onSubmit(values){
      if(this.detailContent=='' && this.audioFileUrl=='' &&this.fileUrl.length===0){
        this.$toast.fail('请最少完成一项反馈，才可提交！')
        return
      }
      // 同意协议
      if(!this.agreementChecked){
        this.agreeConfrim = true;
        return
      }
      let fileUrlList=[]
      for (const item of this.fileUrl) {
        fileUrlList.push(item.url)
      }
      let params={
        softWareType:this.softWareType,
        feedBackType:this.feedBackType,
        detailContent:this.detailContent,
        audioFileUrl:this.audioFileUrl,
        fileUrl:fileUrlList.join(),
        linkName:this.linkName,
        telNumber:this.telNumber,
        mobileVerifyCode:this.mobileVerifyCode
      }
      this.submitLoading=true
      feedback(params).then(res=>{
        this.$toast.success('提交成功！')
        this.codeText='获取验证码'
        this.resetForm()
      }).finally(()=>{
        this.submitLoading=false
      })
    },
    // 软件选择
    onConfirm(val){
      this.softWareTypeLabel = val.label;
      this.softWareType = val.value;
      this.showPicker = false;
    },
    // 同意用户隐私协议
    consentAgreement(){
      this.agreeConfrim = false;
      this.agreementChecked = true;
      this.smsClick()
    },
    // 发送验证码
    smsClick(){
      this.$refs.form.resetValidation()
      this.$refs.telNumberField.validate().then(res=>{
        if(res){
          return
        }
        if(!this.agreementChecked){
          this.agreeConfrim = true;
          return
        }
        if(!this.codeDisabled){
          if (!this.smstimer) {
            let time = 60;
            this.codeText = `${time}秒后重新获取`;
            this.codeDisabled = true;
            this.smstimer = setInterval(() => {
              if (time > 1) {
                time = time - 1;
                this.codeText = `${time}秒后重新获取`;
              } else {
                clearInterval(this.smstimer);
                this.codeText = '重新发送';
                this.codeDisabled = false;
                this.smstimer = null;
              }
            }, 1000);
          }
          //发送验证码
          mobile_smsCode(this.telNumber).then((res) => {
            this.$toast.success(res)
          });
        }
      })
    },
    GoBack(){
      this.$router.go(-1);
    },
  }
});
</script>

<style lang="less" scoped>
.userFeedback{
  .agree-checkbox {
    width: 280px;
    font-size: 13px;
    padding: 0.3rem 0.4rem;
    /deep/.van-checkbox,
    /deep/.van-checkbox__icon,
    /deep/.van-checkbox__icon .van-icon {
      display: inline;
    }
    /deep/.van-checkbox__label {
      line-height: 11px;
    }
    .xy {
      color: #ff8260;
    }
  }
}
.agreement {
  font-size: 12px;
  color: #333;
  &-title {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
  }
  &-content {
    padding: 0 15px;
  }
}
.agree-tip {
  font-size: 12px;
  padding: 20px 30px;
  color: #000;
  .xy {
    color: #ff8260;
  }
}
.agree-btn {
  /deep/.van-button {
    width: 94px;
  }
  /deep/.van-button--default {
    background: #f0f4ff;
  }
  display: flex;
  justify-content: space-between;
  padding: 0 60px;
  padding-bottom: 24px;
}
.radioGroup /deep/ .van-radio--horizontal{
  margin-bottom: 10px;
}
.small-triangle{
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #4e81ee;
    margin-right: -3px;
  }
  .prompt{
    display: flex;
    align-items: center;
    &-content{
      display: flex;
      align-items: center;
      font-size: 13px;
      color: white;
      background-color: #4e81ee;
      padding: 5px 15px;
      border-radius: 5px;
    }
  }
.soundRecording{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 0px 0px 50px 0px;
  &-action{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    margin-top:15px
  }
  .reRecord{
    display: flex;
    flex-direction: column;
    align-items: center;
    .reRecordIcon{
      border: 1px solid #ebeef5;
      padding: 10px;
      border-radius: 50%;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
    }
    span{
      margin-top:10px;
      font-size: 13px;
      color: #717171;
    }
  }
}
.recordingDuration{
  text-align: center;
  font-size: 25px;
  color: #333333;
}


@--h: 80px;
.container {
  display: flex;
  align-items: center;
  position: relative;
  height: @--h;
  justify-content: center;
}
.container-play span{
  animation: loading 1.5s infinite linear;
  animation-delay: calc(0.5s * var(--d));
}
.container-stop span{
  animation: '';
  animation-delay: '';
}
.container span {
  background: linear-gradient(to top, #727272 0%, #727272 100%);
  width: 2px;
  height: 15%;
  border-radius: calc(@--h * 0.2 * 0.5);
  margin-right: 4px;
}
.container span:last-child {
  margin-right: 0px;
}
@keyframes loading {
  0% {
    background-image: linear-gradient(to right, #727272 0%, #727272 100%);
    height: 15%;
    border-radius: calc(@--h * 0.2 * 0.5);
  }
  50% {
    background-image: linear-gradient(to top, #727272 0%, #727272 100%);
    height: 40%;
    border-radius: calc(@--h * 1 * 0.5);
  }
  100% {
    background-image: linear-gradient(to top, #727272 0%, #727272 100%);
    height: 15%;
    border-radius: calc(@--h * 0.2 * 0.5);
  }
}
</style>